.date-range-pickers {
  position: absolute;
  top: 6px;
  right: 20px;
  z-index: 1000;

}

@media screen and (max-width: 960px) {
  .date-range-pickers {
    display: flex;
    right: -4px;
  }
}