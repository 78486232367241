.input {
  align-items: center;
  position: relative;
  width: 100%;
}
.arrow-icons {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  
  &_up {
    position: absolute;
    top: 2px;
    right: 4px;
  }

  &_down {
    position: absolute;
    bottom: 2px;
    right: 4px;
  }
}