.main {
  position: relative;
}

.colorButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.colorLabel {
  width: 40px;
  height: 40px;
  border: 2px solid #ccc;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 10px;
  cursor: pointer;
}