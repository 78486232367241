.paper {
  min-height: 200px;
  padding: 24px 60px;
  width: 100%;
}

.qrWrap {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.buttonWrap {
  padding-top: 10px;
  height: 54px;
}