.paper {
  min-height: 200px;
  padding: 18px;
  width: 100%;
}

.gridItem {
  display: flex;
}

.respondentsWrap {
  width: 100%;
}
