body {
  background: rgba(227, 28, 94, 1)
}
.main {
  padding-top: 40px;
}
.logo {
  text-align: center;
  color: #fff;
  font-family: 'Gilroy';
  font-size: 22px;
  margin-bottom: 18px;

  &_desc {
    font-size: 16px;
  }
}
.not-found-wrapper {
  text-align: center;
  padding-top: 60px;
  color: #fff;

  a {
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }
}