.deviceWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

.device {
  height: 760px;
  width: 397px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.frame {
  position: relative;
  top: 70px;
  left: 25px;
  width: 350px;
  height: 625px;
}